import React from 'react';

import Page404 from 'components/404';
import SEO from 'components/seo';
import Layout from 'components/Layout';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Page404 />
  </Layout>
);

export default NotFoundPage;
