import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import { Title, SubTitle, Text } from 'components/ui/Typography';
import Header from 'components/ui/Header';

import { withPrefix } from 'gatsby';
import Footer from 'components/ui/Footer';
import {
  useTransition, animated, useSpring, useChain, config,
} from 'react-spring';
import Button from 'components/ui/Button';
import css from './style.module.scss';

const charlesImagesNumber = 10;

const getCharlesImages = () => Array(charlesImagesNumber)
  .fill()
  .map((_, i) => ({
    src: withPrefix(`/charles/${i + 1}.jpg`),
    className: cn(css.charlesImage, css[`charlesImage${i + 1}`]),
    index: i,
  }));

export const Page404 = ({ fullPage }) => {
  const classes = cn(css.page404, fullPage && css.fullPage);
  const [animate, startAnimation] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      startAnimation(true);
    }, 400);
  }, []);

  const imagesRef = useRef();
  const data = getCharlesImages();
  const transitions = useTransition(animate ? data : [], item => item.index, {
    unique: true,
    ref: imagesRef,
    trail: 1500 / data.length,
    from: { opacity: 0, transform: 'translateX(30px)' },
    enter: { opacity: 1, transform: 'translateX(0)' },
    leave: { opacity: 0, transform: 'translateX(30px)' },
  });

  const creditsRef = useRef();
  const creditsProps = useSpring({
    ref: creditsRef,
    config: config.stiff,
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  useChain([imagesRef, creditsRef]);

  return (
    <div className={classes}>
      {fullPage && (
        <div className={css.container}>
          <Header onlyLogo />
        </div>
      )}
      {/* <Title>Ooops :(</Title> */}
      <div className={cn(css.content)}>
        <Title>We couldn't find the page you were looking for.</Title>
        <SubTitle className={css.subtitle}>
          But at least we found this cool dog, and we want to share it with you.
        </SubTitle>
        <Button link to="/">
          Return to homepage
        </Button>
      </div>
      <div className={css.charles}>
        {transitions.map(({ item, key, props }) => (
          <animated.img
            src={item.src}
            alt="Charles, an amazing dog"
            key={item.index}
            loading="lazy"
            className={item.className}
            style={props}
          />
        ))}
        <animated.div style={creditsProps}>
          <Text>
            Photos by
            {' '}
            <a href="https://unsplash.com/@charlesdeluvio">Charles</a>
            {' '}
on
            {' '}
            <a href="https://unsplash.com/">Unsplash</a>
          </Text>
        </animated.div>
      </div>
      {fullPage && (
        <div className={css.container}>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Page404;
